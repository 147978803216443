<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="400px" scrollable>
      <v-card>
        <v-card-title>
          <span class="headline">{{ labels.title }}</span>
        </v-card-title>
        <v-card-text>
          <v-form lazy-validation ref="ChangePasswordForm">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    :append-icon="showCurrent ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showCurrent ? 'text' : 'password'"
                    @click:append="showCurrent = !showCurrent"
                    v-model="currentPassword"
                    :label="labels.current_password"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :append-icon="showNew ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showNew ? 'text' : 'password'"
                    @click:append="showNew = !showNew"
                    v-model="newPassword"
                    :label="labels.new_password"
                    :rules="[rules.required, rules.password_size]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showConfirm ? 'text' : 'password'"
                    @click:append="showConfirm = !showConfirm"
                    v-model="confirmNewPassword"
                    :label="labels.confirm_new_password"
                    :rules="[rules.required, rules.password_size]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="gray" text @click="closeDialog">{{
            labels.button_cancel
          }}</v-btn>
          <v-btn @click="updatePassword" :loading="loading">{{
            labels.button_update
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      showCurrent: false,
      showNew: false,
      showConfirm: false,
    };
  },
  computed: {
    labels: {
      get() {
        return this.$store.getters["labels"]("change_password");
      },
    },
    rules: {
      get() {
        return this.$store.getters["rules"];
      },
    },
  },
  methods: {
    closeDialog() {
      this.$emit("hide-dialog");
    },
    updatePassword() {
      if (this.validateForm()) {
        this.loading = true;
        this.$store
          .dispatch("auth/changePassword", {
            current_password: this.currentPassword,
            password: this.newPassword,
            password_confirmation: this.confirmNewPassword,
          })
          .then(() => {
            this.loading = false;
            this.$store.commit("snackbar/show", {
              text: this.labels.events.success.update_password,
              type: "success",
            });
            this.closeDialog();
          })
          .catch((error) => {
            debugger;
            if (error.response.data.errors) {
              this.$store.commit("snackbar/show", {
                text: error.response.data.errors.current_password
                  ? error.response.data.errors.current_password[0]
                  : this.labels.events.error.update_password,
                type: "error",
              });
            } else
              this.$store.commit("snackbar/show", {
                text: this.labels.events.error.update_password,
                type: "error",
              });
            this.loading = false;
          });
      }
    },
    validateForm() {
      if (this.$refs.ChangePasswordForm.validate()) {
        if (this.newPassword !== this.confirmNewPassword) {
          this.$store.commit("snackbar/show", {
            text: this.labels.events.error.update_password_confirm,
            type: "error",
          });
          return false;
        } else return true;
      } else return false;
    },
  },
};
</script>