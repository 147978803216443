<template>
  <v-toolbar dense flat :color="color ? color : 'grey'" class="fixed-bar">
    <v-progress-linear
      :active="progress"
      :indeterminate="progress"
      absolute
      bottom
      color="primary"
    ></v-progress-linear>
    <slot name="buttons" v-bind:lb="labels" v-bind:can="userCan"></slot>
    <v-spacer></v-spacer>
  </v-toolbar>
</template>
<script>
export default {
  name: "Toolbar",
  props: {
    entity: {
      type: String,
    },
    progress: {
      type: Boolean,
    },
    color: {
      type: String,
    },
  },
  computed: {
    labels: {
      get() {
        return this.$store.getters["labels"]("toolbar");
      },
    },
    userCan: {
      get() {
        return this.$store.getters["auth/userCan"](this.entity);
      },
    },
  },
};
</script>
<style scoped>
.fixed-bar {
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  top: 3em;
  z-index: 2;
}
</style>